@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  font-family: NeueMontreal;
  font-weight: normal;
}
h1 {
  font-family: NeueMontreal;
  font-weight: bold;
}

h4 {
  font-family: NeueMontreal;
  font-weight: normal;
}

h6 {
  font-family: NeueMontreal-Light;
  font-weight: lighter;
}

.header {
  font-size: larger;
  font-family: NeueMontreal-Bold;
  font-weight: lighter;
}

code {
  font-family: NeueMontreal, source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.about {
  width: 35%;
  text-align: center;
  margin: auto;
}

.projects {
  width: 35%;
  text-align: center;
  margin: auto;
}

.portfolio{
  font-weight: 400;
}
.App {
  text-align: center;
}
@font-face {
  font-family: 'NeueMontreal';
  font-weight: 600;
  src: local('NeueMontreal'), url(/static/media/NeueMontreal-Light.19737cc1.otf) format('opentype');
}

@font-face {
  font-family: 'NeueMontreal';
  font-weight: 900;
  src: local('NeueMontreal'), url(/static/media/NeueMontreal-Bold.821f2668.otf) format('opentype');
}
@font-face {
  font-family: 'NeueMontreal';
  src: local('NeueMontreal'), url(/static/media/NeueMontreal-LightItalic.045ca91a.otf) format('opentype');
  font-style: italic;
}

.App-header {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
}
.App-link {
  color: #0042fb;
}
hr {width: 15%;margin-left: auto;margin-right: auto;margin-top: 0px;}


