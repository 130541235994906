@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  font-family: NeueMontreal;
  font-weight: normal;
}
h1 {
  font-family: NeueMontreal;
  font-weight: bold;
}

h4 {
  font-family: NeueMontreal;
  font-weight: normal;
}

h6 {
  font-family: NeueMontreal-Light;
  font-weight: lighter;
}

.header {
  font-size: larger;
  font-family: NeueMontreal-Bold;
  font-weight: lighter;
}

code {
  font-family: NeueMontreal, source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.about {
  width: 35%;
  text-align: center;
  margin: auto;
}

.projects {
  width: 35%;
  text-align: center;
  margin: auto;
}

.portfolio{
  font-weight: 400;
}