.App {
  text-align: center;
}
@font-face {
  font-family: 'NeueMontreal';
  font-weight: 600;
  src: local('NeueMontreal'), url(./fonts/NeueMontreal-Light.otf) format('opentype');
}

@font-face {
  font-family: 'NeueMontreal';
  font-weight: 900;
  src: local('NeueMontreal'), url(./fonts/NeueMontreal-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'NeueMontreal';
  src: local('NeueMontreal'), url(./fonts/NeueMontreal-LightItalic.otf) format('opentype');
  font-style: italic;
}

.App-header {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
}
.App-link {
  color: #0042fb;
}
hr {width: 15%;margin-left: auto;margin-right: auto;margin-top: 0px;}

